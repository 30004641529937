import { UserLoginDetails } from '../Hostlink/UserLoginDetails'
import React, { useEffect, useState, useContext, useRef } from 'react'
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import Loading from '../Component/Loading';

const Rpt_MIS_Report = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [Days_calculateMTD, setDays_calculateMTD] = useState(0);
    const [Days_calculateYTD, setDays_calculateYTD] = useState(0);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopdeptcode = appState?.[0]?.deptcode || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;

    const queryCondition = usershopidref == 0
        ? `${usershopid}`
        : `${usershopidref}`;
    const [RoomType, setRoomType] = useState([]);
    const [Room, setRoom] = useState([]);
    const [deptdata, setdeptdata] = useState([])
    const [data, setData] = useState([]);
    const today = new Date();

    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        deptcode: usershopdeptcode,
        status: 0
    });
    const [totalSums, setTotalSums] = useState({});

    const { fromdate, todate, status, deptcode } = frmdata;
    const tableRef = useRef(null);
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };
    // Function to calculate MTD (Month to Date)
    const calculateMTD = () => {
        // const today = new Date();
        const today = new Date(fromdate);;
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // Get first day of the current month

        // Calculate the difference in days
        const timeDiff = today - firstDayOfMonth; // Time difference in milliseconds
        const daysPassed = Math.floor(timeDiff / (1000 * 3600 * 24)); // Convert milliseconds to days
        return daysPassed;
    };

    // Function to calculate YTD (Year to Date)
    const calculateYTD = () => {
        const today = new Date(fromdate);;
        // If today's date is before April, use the previous year as the fiscal year's start date
        const fiscalYearStart = today.getMonth() >= 3 // Month is 0-indexed, so 3 is April
            ? new Date(today.getFullYear(), 3, 1)  // Starting from April 1st of the current year
            : new Date(today.getFullYear() - 1, 3, 1);  // Starting from April 1st of the previous year

        // Calculate the difference in days
        const timeDiff = today - fiscalYearStart; // Time difference in milliseconds
        const daysPassed = Math.floor(timeDiff / (1000 * 3600 * 24)); // Convert milliseconds to days

        return daysPassed;
    };

    

    useEffect(() => {
        setDays_calculateMTD(calculateMTD() + 1);
        setDays_calculateYTD(calculateYTD());
    }, [usershopid,fromdate])
    // (select sum(advance) from checkinmas where resvno = 0 and checkinmas.shopid = ${usershopid} and checkinmas.indate >= CURDATE() - INTERVAL (DAYOFMONTH(CURDATE()) - 1) DAY) as checkinroomservicedetailsM,
    const loaddata = async (e) => {
        console.log(Days_calculateMTD);
        console.log(Days_calculateYTD);

        // (select count(*) from checkinroomdetails where shopid = ${usershopid} and chdate >= STR_TO_DATE(CONCAT(YEAR(CURDATE()), '-04-01'), '%Y-%m-%d') and chdate <= CURDATE()) as OccupidRoomYTD
        setIsLoading(true)
        try {
            const sqlQuery = `select 
    (select count(*) from roommas where shopid = ${usershopid}) as Totalroom,
    (select count(*) from roommas where status = 1 and shopid = ${usershopid}) as TotalroomBooked,
    (select count(*) from checkinroomdetails where shopid = ${usershopid} and DATE(chdate) >= DATE('${fromdate}') - INTERVAL (DAYOFMONTH('${fromdate}') - 1) DAY) as OccupidRoomMTD,
    (select count(*) from checkinroomdetails where shopid = ${usershopid}) as OccupidRoomYTD,

    (select sum(netpayamt) from billmas where shopid = ${usershopid} and bldate = '${fromdate}' ) as RoomRent,
    (select sum(netpayamt) from billmas where shopid = ${usershopid} and DATE(bldate) >= DATE('${fromdate}') - INTERVAL (DAYOFMONTH('${fromdate}') - 1) DAY) as RoomRentM,
    (select sum(netpayamt) from billmas where shopid = ${usershopid}) as RoomRentY,

    (select sum(foodamount) from billmas where shopid = ${usershopid} and bldate = '${fromdate}' ) as foodamount,
    (select sum(foodamount) from billmas where shopid = ${usershopid} and DATE(bldate) >= DATE('${fromdate}') - INTERVAL (DAYOFMONTH('${fromdate}') - 1) DAY) as foodamountM,
    (select sum(foodamount) from billmas where shopid = ${usershopid}) as foodamountY,

    (select sum(exbedch*exbed) from checkinroomdetails,billmas where billmas.checkinid = checkinroomdetails.chid and billmas.shopid = ${usershopid} and billmas.bldate = '${fromdate}' ) as exbedch,
    (select sum(exbedch*exbed) from checkinroomdetails,billmas where billmas.checkinid = checkinroomdetails.chid and billmas.shopid = ${usershopid} and DATE(billmas.bldate) >= DATE('${fromdate}') - INTERVAL (DAYOFMONTH('${fromdate}') - 1) DAY) as exbedchD,
    (select sum(exbedch*exbed) from checkinroomdetails,billmas where billmas.checkinid = checkinroomdetails.chid and billmas.shopid = ${usershopid}) as exbedchYTD,

    (select sum(advance) from reservationmas where shopid = ${usershopid} and resdate = '${fromdate}' ) as advance,
    (select sum(advance) from reservationmas where shopid = ${usershopid} AND DATE(resdate) >= DATE('${fromdate}') - INTERVAL (DAYOFMONTH('${fromdate}') - 1) DAY) as advanceM,
    (select sum(advance) from reservationmas where shopid = ${usershopid}) as advanceY,

    (select sum(ittotal) from checkinroomservicedetails where shopid = ${usershopid} and orddate = '${fromdate}' ) as checkinroomservicedetails,
    (select sum(ittotal) from checkinroomservicedetails where shopid = ${usershopid} and DATE(orddate) >= DATE('${fromdate}') - INTERVAL (DAYOFMONTH('${fromdate}') - 1) DAY) as checkinroomservicedetailsM,
    (select sum(ittotal) from checkinroomservicedetails where shopid = ${usershopid}) as checkinroomservicedetailsY,

    (select sum(ittotal) from salemas where shopid = ${usershopid} and bldate = '${fromdate}' ) as checkinroomservicedetails,
    (select sum(ittotal) from salemas where shopid = ${usershopid} and DATE(bldate) >= DATE('${fromdate}') - INTERVAL (DAYOFMONTH('${fromdate}') - 1) DAY) as checkinroomservicedetailsM,
    (select sum(ittotal) from salemas where shopid = ${usershopid}) as checkinroomservicedetailsY,

    (select sum(vamt) from vouchermas,accmas where accmas.id = vouchermas.acccode and vouchermas.vtype = 5 and accmas.acctype = 2 and vouchermas.shopid = ${usershopid} and vouchermas.entrydate = '${fromdate}' ) as checkinroomservicedetails,
    (select sum(vamt) from vouchermas,accmas where accmas.id = vouchermas.acccode and vouchermas.vtype = 5 and accmas.acctype = 2 and vouchermas.shopid = ${usershopid} and DATE(vouchermas.entrydate) >= DATE('${fromdate}') - INTERVAL (DAYOFMONTH('${fromdate}') - 1) DAY) as checkinroomservicedetailsM,
    (select sum(vamt) from vouchermas,accmas where accmas.id = vouchermas.acccode and vouchermas.vtype = 5 and accmas.acctype = 2 and vouchermas.shopid = ${usershopid}) as checkinroomservicedetailsY,

    (select sum(totalvamount) from vouchermas,accmas where accmas.id = vouchermas.acccode and vouchermas.vtype in (3) and accmas.acctype in (0,1) and vouchermas.shopid = ${usershopid} and vouchermas.entrydate = '${fromdate}' ) as checkinroomservicedetails,
    (select sum(totalvamount) from vouchermas,accmas where accmas.id = vouchermas.acccode and vouchermas.vtype in (3) and accmas.acctype in (0,1) and vouchermas.shopid = ${usershopid} and DATE(vouchermas.entrydate) >= DATE('${fromdate}') - INTERVAL (DAYOFMONTH('${fromdate}') - 1) DAY) as checkinroomservicedetailsM,
    (select sum(totalvamount) from vouchermas,accmas where accmas.id = vouchermas.acccode and vouchermas.vtype in (3) and accmas.acctype in (0,1) and vouchermas.shopid = ${usershopid}) as checkinroomservicedetailsY,

    (select sum(totalvamount) from vouchermas,accmas where accmas.id = vouchermas.acccode and vouchermas.vtype in (4) and accmas.acctype in (0,1) and vouchermas.shopid = ${usershopid} and vouchermas.entrydate = '${fromdate}' ) as checkinroomservicedetails,
    (select sum(totalvamount) from vouchermas,accmas where accmas.id = vouchermas.acccode and vouchermas.vtype in (4) and accmas.acctype in (0,1) and vouchermas.shopid = ${usershopid} and DATE(vouchermas.entrydate) >= DATE('${fromdate}') - INTERVAL (DAYOFMONTH('${fromdate}') - 1) DAY) as checkinroomservicedetailsM,
    (select sum(totalvamount) from vouchermas,accmas where accmas.id = vouchermas.acccode and vouchermas.vtype in (4) and accmas.acctype in (0,1) and vouchermas.shopid = ${usershopid}) as checkinroomservicedetailsY,

    (select sum(advance) from checkinmas where resvno = 0 and checkinmas.shopid = ${usershopid} and checkinmas.indate = '${fromdate}' ) as checkinroomservicedetails,
    (select sum(advance) from checkinmas where resvno = 0 and checkinmas.shopid = ${usershopid} and DATE(checkinmas.indate) >= DATE('${fromdate}') - INTERVAL (DAYOFMONTH('${fromdate}') - 1) DAY) as checkinroomservicedetailsM,
    (select sum(advance) from checkinmas where resvno = 0 and checkinmas.shopid = ${usershopid}) as checkinroomservicedetailsY

    
    from shopmas where id =${usershopid}`;
            // console.log(sqlQuery)
            const encodedSqlQuery = encodeURIComponent(sqlQuery);
            // console.log(`${hostlink}/api/executeQuery?sqlQuery=${encodedSqlQuery}`)
            const billResponse = await axios.post(`${hostlink}/api/executeQueryNew`, { sqlQuery });

            // const billResponse = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=${encodedSqlQuery}`);
            // const billResponse = await axios.post(`${hostlink}/api/executeQuery`, { sqlQuery });

            
            const billData = billResponse.data;
            setData(billResponse.data)
            // console.log(billResponse.data)

            setIsLoading(false)
        } catch (error) {
            console.log("Data Not Found...");
        }
    };
    useEffect(() => {
        axios.get(`${hostlink}/${queryCondition}/deptmas`)
            .then((Response) => { setdeptdata(Response.data) })
    }, [usershopid])

    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'MIS Report.xlsx');
    };

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-12 align-self-center text-center'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-object-group"></i> <span id='pagemode'> MIS Report</span></h5>
                        {/* <p className='align-self-center'>Date : <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control w-25" /></p> */}
                    </div>
                    <div className='col-md-12 align-self-center text-center'>
                        
                    </div>
                    <div className='col-md-5 align-self-center text-center'>
                    </div>

                    <div className='col-md-6 align-self-center text-center' style={{display:"flex"}}>
                        <p className='align-self-center'>Date :</p> &nbsp; <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control w-25" />
                    </div>

                    <div className='col-md-12 align-self-center text-end mt-2'>
                        {/* <button onClick={() => { loaddata(0); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Department-wise </button> */}
                        <button onClick={() => { loaddata(1); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show</button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>
                        {/* <PDFDownloadLink document={<PDFDocument data={data} fromdate={fromdate} todate={todate} />} fileName="MIS Report.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading' : <button className='btn btn-sm btn-outline-primary' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-regular fa-file-pdf"></i> PDF</button>
                            }
                        </PDFDownloadLink> */}
                    </div>
                </div>
            </div>


            <br />
            {/* maxHeight: "400px", */}
            <div className='container-fluid' style={{ overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center' >
                        <tr >
                            <th className='text-start w-50' scope="col">Particular</th>
                            <th className='text-end' scope="col">Today</th>
                            <th className='text-end' scope="col">Month To Date</th>
                            <th className='text-end' scope="col">Year To Date</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {data.map((res, x) => (
                            <>
                                <tr key={x}>
                                    <td className='text-start'>Total Room</td>
                                    <td className='text-end'>{res[0]}</td>
                                    <td className='text-end'>{res[0] * Number(Days_calculateMTD)}</td>
                                    <td className='text-end'>{res[0] * Days_calculateYTD}</td>
                                </tr>
                                <tr key={x}>
                                    <td className='text-start'>Occupied Room</td>
                                    <td className='text-end'>{res[1]}</td>
                                    <td className='text-end'>{res[2]}</td>
                                    <td className='text-end'>{res[3]}</td>
                                </tr>
                                <tr key={x}>
                                    <td className='text-start'>Complementary Room</td>
                                    <td className='text-end'>0.00</td>
                                    <td className='text-end'>0.00</td>
                                    <td className='text-end'>0.00</td>
                                </tr>

                                <tr key={x}>
                                    {/* <td className='text-start'><b>Room Rent (Hotel)</b></td> */}
                                    <td className='text-start'><b><b>Hotel Bill</b></b></td>
                                    <td className='text-end'>{Number(res[4]).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[5]).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[6]).toFixed(2)}</td>
                                </tr>
                                <tr key={x}>
                                    <td className='text-start'>Food Plan</td>
                                    <td className='text-end'>{Number(res[7]).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[8]).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[9]).toFixed(2)}</td>
                                </tr>
                                <tr key={x}>
                                    <td className='text-start'>Extra Bed</td>
                                    <td className='text-end'>{Number(res[10]).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[11]).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[12]).toFixed(2)}</td>
                                </tr>
                                <tr key={x}>
                                    <td className='text-start'>Advance Amount (Reservation)</td>
                                    <td className='text-end'>{Number(res[13] + res[31]).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[14] + res[32]).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[15] + res[33]).toFixed(2)}</td>
                                </tr>
                                <tr key={x}>
                                    <td className='text-start'>Room Service</td>
                                    <td className='text-end'>{Number(Number(res[16])).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[17]).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[18]).toFixed(2)}</td>
                                </tr>
                                <tr key={x}>
                                    <td className='text-start'>Packing</td>
                                    <td className='text-end'>0.00</td>
                                    <td className='text-end'>0.00</td>
                                    <td className='text-end'>0.00</td>
                                </tr>
                                <tr key={x}>
                                    <td className='text-start'>Other Services</td>
                                    <td className='text-end'>0.00</td>
                                    <td className='text-end'>0.00</td>
                                    <td className='text-end'>0.00</td>
                                </tr>
                                <tr key={x}>
                                    <td className='text-start'>Other Services (Room)</td>
                                    <td className='text-end'>0.00</td>
                                    <td className='text-end'>0.00</td>
                                    <td className='text-end'>0.00</td>
                                </tr>

                                <tr key={x}>
                                    <td className='text-start'><b>Total Sale (Restaurant)</b></td>
                                    <td className='text-end'>{Number(res[19]).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[20]).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[21]).toFixed(2)}</td>
                                </tr>

                                <tr key={x}>
                                    <td className='text-start'><b>Credit Hotel Bill</b></td>
                                    <td className='text-end'>{Number(res[22]).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[23]).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[24]).toFixed(2)}</td>
                                </tr>

                                <tr key={x}>
                                    <td className='text-start'><b>Transaction - Receipt</b></td>
                                    <td className='text-end'>{Number(res[25]).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[26]).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[27]).toFixed(2)}</td>
                                </tr>

                                <tr key={x}>
                                    <td className='text-start'><b>Transaction - Payment</b></td>
                                    <td className='text-end'>{Number(res[28]).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[29]).toFixed(2)}</td>
                                    <td className='text-end'>{Number(res[30]).toFixed(2)}</td>
                                </tr>

                                <tr class="table-info">
                                    <td className='text-end'><b>Total</b></td>
                                    <td className='text-end'><b>{Number((Number(data[0][13]) + data[0][31] + data[0][19] + data[0][4] + data[0][25]) - Number(data[0][22] + data[0][28])).toFixed(2)}</b></td>
                                    <td className='text-end'><b>{Number((Number(data[0][14]) + data[0][32] + data[0][20] + data[0][5] + data[0][26]) - Number(data[0][23] + data[0][29])).toFixed(2)}</b></td>
                                    <td className='text-end'><b>{Number((Number(data[0][15]) + data[0][33] + data[0][21] + data[0][6] + data[0][27]) - Number(data[0][24] + data[0][30])).toFixed(2)}</b></td>
                                </tr>
                            </>


                        ))}

                    </tbody>
                </table>
            </div>
            {isLoading && <Loading />}
        </div>
    )
}
const PDFDocument = ({ data, fromdate, todate }) => {
    // Calculate total bill amount
    // const totalBillAmount = data.reduce((total, res) => total + parseFloat(res.SaleMasDTO.totblamt), 0);
    const calculateMTD = () => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // Get first day of the current month

        // Calculate the difference in days
        const timeDiff = today - firstDayOfMonth; // Time difference in milliseconds
        const daysPassed = Math.floor(timeDiff / (1000 * 3600 * 24)); // Convert milliseconds to days
        return daysPassed;
    };

    // Function to calculate YTD (Year to Date)
    const calculateYTD = () => {
        const today = new Date();
        // If today's date is before April, use the previous year as the fiscal year's start date
        const fiscalYearStart = today.getMonth() >= 3 // Month is 0-indexed, so 3 is April
            ? new Date(today.getFullYear(), 3, 1)  // Starting from April 1st of the current year
            : new Date(today.getFullYear() - 1, 3, 1);  // Starting from April 1st of the previous year

        // Calculate the difference in days
        const timeDiff = today - fiscalYearStart; // Time difference in milliseconds
        const daysPassed = Math.floor(timeDiff / (1000 * 3600 * 24)); // Convert milliseconds to days

        return daysPassed;
    };

    const Days_calculateMTD = calculateMTD();
    const Days_calculateYTD = calculateYTD();

    return (
        <Document>
            <Page orientation="landscape">
                <View style={styles.container}>
                    <Text style={styles.title}>MIS Report</Text>
                    <Text style={styles.title}>Date : {fromdate}</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.headerCell}>Particular</Text>
                            <Text style={styles.headerCell}>Today</Text>
                            <Text style={styles.headerCell}>Month To Date</Text>
                            <Text style={styles.headerCell}>Year To Date</Text>
                        </View>
                        {data.map((res, x) => (
                            <>
                                <View key={x} style={styles.tableRow}>
                                    <Text style={styles.cell}>Total Room</Text>
                                    <Text style={styles.cell}>{res[0]}</Text>
                                    <Text style={styles.cell}>{res[0] * Number(Days_calculateMTD)}</Text>
                                    <Text style={styles.cell}>{res[0] * Days_calculateYTD}</Text>
                                </View>
                                <View key={x} style={styles.tableRow}>
                                    <Text style={styles.cell}>occupied Room</Text>
                                    <Text style={styles.cell}>{res[1]}</Text>
                                    <Text style={styles.cell}>{res[2]}</Text>
                                    <Text style={styles.cell}>{res[3]}</Text>
                                </View>
                                <View key={x} style={styles.tableRow}>
                                    <Text style={styles.cell}>Complementary Room</Text>
                                    <Text style={styles.cell}>0.00</Text>
                                    <Text style={styles.cell}>0.00</Text>
                                    <Text style={styles.cell}>0.00</Text>
                                </View>
                                <View key={x} style={styles.tableRow}>
                                    <Text style={styles.cell}>Room Rent</Text>
                                    <Text style={styles.cell}>{res[4]}</Text>
                                    <Text style={styles.cell}>{res[5]}</Text>
                                    <Text style={styles.cell}>{res[6]}</Text>
                                </View>
                                <View key={x} style={styles.tableRow}>
                                    <Text style={styles.cell}>Food Plan</Text>
                                    <Text style={styles.cell}>{res[7]}</Text>
                                    <Text style={styles.cell}>{res[8]}</Text>
                                    <Text style={styles.cell}>{res[9]}</Text>
                                </View>
                                <View key={x} style={styles.tableRow}>
                                    <Text style={styles.cell}>Extra Bed</Text>
                                    <Text style={styles.cell}>{res[10]}</Text>
                                    <Text style={styles.cell}>{res[11]}</Text>
                                    <Text style={styles.cell}>{res[12]}</Text>
                                </View>
                                <View key={x} style={styles.tableRow}>
                                    <Text style={styles.cell}>Advance Amount</Text>
                                    <Text style={styles.cell}>{Number(res[13])}</Text>
                                    <Text style={styles.cell}>{res[14]}</Text>
                                    <Text style={styles.cell}>{res[15]}</Text>
                                </View>
                                <View key={x} style={styles.tableRow}>
                                    <Text style={styles.cell}>Room Service</Text>
                                    <Text style={styles.cell}>{Number(res[16])}</Text>
                                    <Text style={styles.cell}>{res[17]}</Text>
                                    <Text style={styles.cell}>{res[18]}</Text>
                                </View>
                                <View key={x} style={styles.tableRow}>
                                    <Text style={styles.cell}>Packing</Text>
                                    <Text style={styles.cell}>0.00</Text>
                                    <Text style={styles.cell}>0.00</Text>
                                    <Text style={styles.cell}>0.00</Text>
                                </View>
                                <View key={x} style={styles.tableRow}>
                                    <Text style={styles.cell}>Other Services</Text>
                                    <Text style={styles.cell}>0.00</Text>
                                    <Text style={styles.cell}>0.00</Text>
                                    <Text style={styles.cell}>0.00</Text>
                                </View>
                                <View key={x} style={styles.tableRow}>
                                    <Text style={styles.cell}>Other Services (Room)</Text>
                                    <Text style={styles.cell}>0.00</Text>
                                    <Text style={styles.cell}>0.00</Text>
                                    <Text style={styles.cell}>0.00</Text>
                                </View>
                                <View key={x} style={styles.tableRow}>
                                    <Text style={styles.cell}></Text>
                                    <Text style={styles.cell}></Text>
                                    <Text style={styles.cell}></Text>
                                    <Text style={styles.cell}></Text>
                                </View>
                                <View key={x} style={styles.tableRow}>
                                    <Text style={styles.cell}>Total Sale</Text>
                                    <Text style={styles.cell}>{Number(res[10])}</Text>
                                    <Text style={styles.cell}>{res[20]}</Text>
                                    <Text style={styles.cell}>{res[21]}</Text>
                                </View>
                            </>
                        ))}
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    title: {
        fontSize: 16,
        fontStyle: 'Times New Roman',
        marginBottom: 20,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    headerCell: {
        fontSize: 8,
        fontWeight: 'bold',
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
    cell: {
        fontSize: 8,
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
});

export default Rpt_MIS_Report