import React, { useEffect, useState, useContext, useRef } from 'react'
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from './Loading';
const KitchenDisplay = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsHotel, setItemsHotel] = useState([]);
  const audioRef = useRef(null);
  const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
  const usershopid = appState?.[0]?.id || 0;

  const usershowhotelkds = appState?.[0]?.showhotelkds || 0;
  const usershowrestkds = appState?.[0]?.showrestkds || 0;
  // Fetch data from the server at regular intervals
  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await axios.get(`${hostlink}/KDSDisplay/${usershopid}/${usershopid}/0/1`);
        const newData = response.data;

        // Compare the data for changes
        if (JSON.stringify(newData) !== JSON.stringify(items)) {
          setItems(newData);
          // Play notification tone for new items
          if (audioRef.current) {
            audioRef.current.play().catch((error) => console.error('Error playing tone:', error));
          }
        }

        const responseHotel = await axios.get(`${hostlink}/KDSDisplayHotel/${usershopid}/${usershopid}/0/1`);
        const newDataHotel = responseHotel.data;
        console.log(JSON.stringify(newDataHotel))
        console.log(JSON.stringify(itemsHotel))
        // Compare the data for changes
        if (JSON.stringify(newDataHotel) !== JSON.stringify(itemsHotel)) {
          setItemsHotel(newDataHotel);
          // Play notification tone for new items
          if (audioRef.current) {
            audioRef.current.play().catch((error) => console.error('Error playing tone:', error));
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Poll every 5 seconds
    const interval = setInterval(fetchData, 3000);

    // Cleanup on component unmount
    return () => clearInterval(interval);
  }, [items, itemsHotel, usershopid]);

  // Mark an item as Ready
  const markAsReady = async (shopvno, status) => {
    setIsLoading(true);
    try {
      await axios.post(`${hostlink}/KDSDisplayUpdateStatus/${usershopid}/${shopvno}/${status}`, { status: 'Ready' });
      // await axios.post(`${hostlink}/KDSDisplayUpdate/${usershopid}/${shopvno}`, { status: 'Ready' });
      toast.success(`Order ${shopvno} marked as Ready.`);

      // Fetch updated data
      const response = await axios.get(`${hostlink}/KDSDisplay/${usershopid}/${usershopid}/0/1`);
      setItems(response.data);

      setIsLoading(false);
    } catch (error) {
      console.error('Error marking item as Ready:', error);
      toast.error('Failed to update order status.');
      setIsLoading(false);
    }
  };

  const markAsReadyHotel = async (shopvno, status) => {
    setIsLoading(true);
    try {
      await axios.post(`${hostlink}/KDSDisplayUpdateStatusHotel/${usershopid}/${shopvno}/${status}`, { status: 'Ready' });
      // await axios.post(`${hostlink}/KDSDisplayUpdate/${usershopid}/${shopvno}`, { status: 'Ready' });
      toast.success(`Order ${shopvno} marked as Ready.`);

      // Fetch updated data
      const response = await axios.get(`${hostlink}/KDSDisplayHotel/${usershopid}/${usershopid}/0/1`);
      setItemsHotel(response.data);

      setIsLoading(false);
    } catch (error) {
      console.error('Error marking item as Ready:', error);
      toast.error('Failed to update order status.');
      setIsLoading(false);
    }
  };


  return (
    <div style={{ padding: '20px', background: "linear-gradient(to bottom, #87CEEB, #ffffff)" }} >
      <h1 className='text-center'>Kitchen Display</h1>
      <div className="container-fluid">
        <div className="row">
          {!(usershowrestkds == 1) && (
            <div className={usershowhotelkds == 1 ? "col-8" : "col-12"}>
              <h1>Restaurant</h1>
              <div className="text-center rounded shadow">
                <table className="table rounded shadow" style={{ border: "1px solid white" }}>
                  <thead className="text-center rounded shadow">
                    <tr>
                      <th className="text-start" scope="col">Order No</th>
                      <th className="text-start" scope="col">Type</th>
                      <th className="text-start" scope="col">Item</th>
                      <th className="text-start" scope="col">Qty</th>
                      <th className="text-start" scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {items.map((res, x) => {
                      // Compare with the previous row's shopvno
                      const isNewGroup = x === 0 || res.kot.shopvno !== items[x - 1].kot.shopvno;

                      return (
                        <tr
                          key={x}
                          style={{
                            borderTop: isNewGroup ? '4px solid green' : 'none',  // Add a thick border for new groups
                          }}
                        >
                          <td className="text-start">{isNewGroup ? res.kot.shopvno : ''}</td>
                          <td className="text-start">{isNewGroup && (<> {res.kottypeName} <br /> Table No: {res.kot.tablename} <br /> Waiter: {res.kot.wname} </>)}
                            {/* {isNewGroup && res.kot.kitchenmessage= && ( <button  
                        style={{ padding: '10px 15px', backgroundColor: res.kot.kdsstatus === 2 ? '#ffc107' : '#28a745',  color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', }} >
                        <i class="fa-regular fa-bell"></i>
                      </button>
                    )} */}
                          </td>
                          <td className="text-start" >{res.kot.itname} {res.kot.itcomment && (<p style={{ color: "red" }}> <b>{res.kot.itcomment}</b></p>)}  </td>
                          <td className="text-start">{res.kot.qty}</td>
                          <td className="text-start">
                            {isNewGroup && (
                              <button
                                onClick={() => {
                                  if (res.kot.kdsstatus === 2) {
                                    markAsReady(res.kot.shopvno, 0);
                                  } else if (res.kot.kdsstatus === 1) {
                                    markAsReady(res.kot.shopvno, 2);
                                  }
                                }}
                                style={{
                                  padding: '10px 15px',
                                  backgroundColor: res.kot.kdsstatus === 2 ? '#ffc107' : '#28a745', // Yellow for "Preparing", Green for "Accept"
                                  color: '#fff',
                                  border: 'none',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                }}
                              >
                                {res.kot.kdsstatus === 2 ? 'Mark as Ready' : 'Mark as Accept'}
                              </button>


                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

              </div>
            </div>
          )}
          {!(usershowhotelkds == 0) && (
            <div className={usershowrestkds === 0 ? "col-4" : "col-12"}>
              <h1>Hotel</h1>
              <div className="text-center rounded shadow">
                <table className="table rounded shadow" style={{ border: "1px solid white" }}>
                  <thead className="text-center rounded shadow">
                    <tr>
                      <th className="text-start" scope="col">Room No</th>
                      <th className="text-start" scope="col">Item</th>
                      <th className="text-start" scope="col">Qty</th>
                      <th className="text-start" scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {itemsHotel.map((res, x) => {
                      if (!res || !res.kot) return null; // Skip undefined or missing data

                      const isNewGroup = x === 0 || res.kot.shopvno !== itemsHotel[x - 1]?.kot?.shopvno;
                      return (
                        <tr key={x} style={{ borderTop: isNewGroup ? '4px solid green' : 'none' }}>
                          <td className="text-start">{isNewGroup ? res.kot.roomnoview : ''}</td>
                          <td className="text-start">
                            {res.kot.rawname}
                            {res.kot.serviceremarks && <p style={{ color: "red" }}><b>{res.kot.serviceremarks}</b></p>}
                          </td>
                          <td className="text-start">{res.kot.qty}</td>
                          <td className="text-start">
                            {isNewGroup && (
                              <button
                                onClick={() => {
                                  if (res.kot.kdsstatus === 2) {
                                    markAsReadyHotel(res.kot.shopvno, 0);
                                  } else if (res.kot.kdsstatus === 1) {
                                    markAsReadyHotel(res.kot.shopvno, 2);
                                  }
                                }}
                                style={{
                                  padding: '10px 15px',
                                  backgroundColor: res.kot.kdsstatus === 2 ? '#ffc107' : '#28a745',
                                  color: '#fff',
                                  border: 'none',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                }}
                              >
                                {res.kot.kdsstatus === 2 ? 'Mark as Ready' : 'Mark as Accept'}
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}

                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Notification sound */}
      <audio ref={audioRef} src="/ready-tone.mp3" preload="auto"></audio>
      {isLoading && <Loading />}
      <ToastContainer autoClose={1000}></ToastContainer>
    </div>
  );
};

export default KitchenDisplay;
