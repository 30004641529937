import React, { useEffect, useState, useContext } from "react";
import { UserLoginDetails } from "../Hostlink/UserLoginDetails";
import hostlink from "../Hostlink/hostlink";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
const Cleaner = () => {
    const location = useLocation();
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const queryParams = new URLSearchParams(location.search);
    const linkid = queryParams.get('id');
    const linkroomname = queryParams.get('roomname');

    const [cleanername, setCleanerName] = useState("");
    const [savedata, setSavedata] = useState({
            id: "",
            cltime: "",
            remark1: "",
            status:0,
            remark2:"",
            cleanername:"",
            roomname:"",
            shopid:""
        })
     const { remark2,remark1 } = savedata;
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }
    const SaveData = async (e) => {
        const myPckartUser = localStorage.getItem('MYPCKARTUSER');if (!myPckartUser){alert("Please Login First..");return false}
       
        //  if (remark1 == "") {
        //             toast.error("Please Enter Remarks...")
        //             document.getElementById("remark1").focus();
        //             document.getElementById("cmdsave").disabled = false
        //             return false
        //         }
        

        document.getElementById("cmdsave").disabled = true
        try {
            const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
            var ld = { ...savedata, cltime:time,status:0,shopid: usershopid,cleanername:cleanername,roomname:linkroomname,remark1:remark1,remark2:remark2 }
                await axios.post(`${hostlink}/ClearLogMas/${linkid}`, ld)
                    .then((Response) => {
                        toast.success("Save Sucess..");
                        setTimeout(() => {
                            
                            window.location.assign("/")
                        }, 1000);
                    })
                    .catch(() => {
                        document.getElementById("cmdsave").disabled = false
                    })
            
        } catch (error) {
        }
    }
    const SaveDataMaintain = async (e) => {
        const myPckartUser = localStorage.getItem('MYPCKARTUSER');if (!myPckartUser){alert("Please Login First..");return false}
       
         if (remark1 == "") {
                    toast.error("Please Enter Remarks...")
                    document.getElementById("remark1").focus();
                    document.getElementById("cmdsave").disabled = false
                    return false
                }
        

        document.getElementById("cmdsave").disabled = true
        try {
            const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
            var ld = { ...savedata, cltime:time,status:1,shopid: usershopid,cleanername:cleanername,roomname:linkroomname,remark1:remark1,remark2:remark2 }
                await axios.post(`${hostlink}/ClearLogMasMaintainance/${linkid}`, ld)
                    .then((Response) => {
                        toast.success("Save Sucess..");
                        setTimeout(() => {
                            
                            window.location.assign("/")
                        }, 1000);
                    })
                    .catch(() => {
                        document.getElementById("cmdsave").disabled = false
                    })
            
        } catch (error) {
        }
    }
    const Closeform = () => {
        window.location.assign("/")
    }
    useEffect(() => {
        if (userType == 3) {
            setCleanerName(appState?.[1][0]?.clname || "");
        } else if (userType == 2) {
            setCleanerName(appState?.[1][0]?.wname || "");
        } else {
            setCleanerName("Admin");
        }
    }, [userType, appState]);

    console.log(cleanername);

    return <div className="p-3"> 
            <div>
                <h1 className="text-center">Cleaner: {cleanername}</h1>
                <h3 className="text-center">Room Name: {linkroomname}</h3>
                <div className="row p-0">
                <div class="col-md-12 col-12">
                    <label className='font-monospace' class="form-label"> Remarks 1</label>
                    <input type="text" onChange={(e) => { onChangevalue(e) }} name='remark1' value={remark1} class="form-control" id="remark1" autoComplete='off' /> <br />
                </div>
                <div className="col-md-12 col-12">
                    <label className='font-monospace' class="form-label"> Remarks 2 </label>
                    <input type="remark2" onChange={(e) => { onChangevalue(e) }} name='remark2' value={remark2} class="form-control" id="remark2" autoComplete="off" /> <br />
                </div>
                <div className="col-md-12 text-end">
                    <button onClick={(e) => (SaveDataMaintain(e))} id='cmdsave' class="btn btn-outline-danger">Mark As Under maintenance</button>  &nbsp;
                    <button onClick={(e) => (SaveData(e))} id='cmdsave' class="btn btn-primary">Mark As Ready to check-in</button>  &nbsp;
                    <button onClick={Closeform} type="submit" class="btn btn-primary">Cancel</button>
                    <br />
                    <br />

                </div>
                </div>
            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
    </div>;
};

export default Cleaner;
