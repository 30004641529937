import axios from 'axios'
import React, { useEffect, useState, useContext, useRef } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import './MessageBox.css'; // 
import './Reservation.css'
import numberToWords from 'number-to-words';
import Loading from './Loading'
import { UserLoginDetails } from '../Hostlink/UserLoginDetails'
import * as XLSX from 'xlsx';

import CheckOut from './CheckOut'
const Reservation_view = () => {
const tableRef = useRef(null);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [itemdetailGrid, setitemdetailGrid] = useState([]);
    const handleFilterChange = (filter) => {
        setSelectedFilter((prevFilter) => (prevFilter === filter ? null : filter));
    };

    const [isLoading, setIsLoading] = useState(false);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const [GST_P_1, setGST_P_1] = useState(0);
    const [GST_P_2, setGST_P_2] = useState(0);
    const [GST_P_3, setGST_P_3] = useState(0);
    const [GST_P_4, setGST_P_4] = useState(0);
    const [rateafterDiscount, setrateafterDiscount] = useState(0);
    const [roomtypeName, setroomtypeName] = useState("NA");
    const today = new Date();
    const [logroom, setLogroom] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenCancel, setIsModalOpenCancel] = useState(false);
    const [isModalOpenCheckIn, setIsModalOpenCheckIn] = useState(false);

    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: ""
    })
    const { deleteid, deletemsg } = messagedata;

    const handleOpenModalCancel = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpenCancel(true);
    };
    const handleOpenModalCheckIn = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpenCheckIn(true);
    };
    const handleOpenModal = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };
    const handleCloseModalCancel = () => { setIsModalOpenCancel(false); };
    const handleNoClickCancel = () => { console.log('User clicked No'); handleCloseModalCancel(); };
    const handleCloseModalCheckIn = () => { setIsModalOpenCheckIn(false); };
    const handleNoClickCheckIn = () => { console.log('User clicked No'); handleCloseModalCheckIn(); };

    const [refreshData, setRefreshData] = useState(false)
    const api = "/Reservation_view";
    const [RoomTYpe, setRoomTYpe] = useState([])
    const [AccCode, setAccCode] = useState([])
    const [roomdetail, setroomdetail] = useState([]);
    const [AccCodeBTC, setAccCodeBTC] = useState([])
    const [Room, setRoom] = useState([])
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState([])
    const Closeform = () => {
        window.location.assign("/Reservation_view")
    }
    const [logdetail, setlogdetail] = useState({
        onform: "Reservation",
        operation: "",
        newdesc: "",
        olddesc: "",
        shopid: usershopid,
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })

    const getroombyid = (e) => {
        // console.log(`${hostlink}/rtmas/${e}`)
        axios.get(`${hostlink}/rtmas/${e}`)
            .then((resp) => {
                setroomtypeName(resp.data.rtname)
            })
            .catch(() => { setroomtypeName("NA") })

        axios.get(`${hostlink}/room/getnamebyid/${e}`)
            .then((Response) => {
                setRoom(Response.data)
            })
            .catch((Response) => { "data Not Found" })
    }
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)

    }
    const [viewbldate, setBldate] = useState({
        blfromdate: today.toISOString().split('T')[0],
        bltodate: today.toISOString().split('T')[0]
    })
    const { blfromdate, bltodate } = viewbldate;
    const bldatechange = (e) => {
        console.log(e.target.value)
        const { name, value } = e.target;
        setBldate({ ...viewbldate, [name]: value })
        setRefreshData(true)
    }

    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}/${usershopid}/reservationviewFilterReport/${blfromdate}/${bltodate}/${selectedFilter}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/${usershopid}${api}/search/${document.getElementById("search").value}`)

                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        }
    }
    const SaveData = async (e) => {

        const time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

        for (let i = 0; i < roomdetail.length; i++) {
            roomdetail[i].resdate = resdate;
            roomdetail[i].restime = time;
            roomdetail[i].gmob = gmob;
            roomdetail[i].refrence = refrence;
            roomdetail[i].gname = gname;
            roomdetail[i].billtocompany = billtocompany;
            roomdetail[i].billtocompanyname = billtocompanyname;
            roomdetail[i].add1 = add1;
            roomdetail[i].add2 = add2;
            roomdetail[i].email = email;
            roomdetail[i].shopid = usershopid;
            roomdetail[i].advance = advance;
            roomdetail[i].remarks = remarks;
            roomdetail[i].cname = cname;
            roomdetail[i].mg = mg;
            roomdetail[i].fg = fg;
            roomdetail[i].cg = cg;
            roomdetail[i].paidby = paidby;
            roomdetail[i].documentno = documentno;
            roomdetail[i].gstno = gstno;
            roomdetail[i].shopresno = shopresno;
            roomdetail[i].narration = time + usershopid + roomdetail[i].roomtypename;
            roomdetail[i].acccode = acccode;
        }
        // console.log(roomdetail);
        // return false;
        setIsLoading(true);

        if (document.getElementById("1inputnamecheckin").value == "") {
            setIsLoading(false);
            toast.error("Please Select check in Date...")
            document.getElementById("1inputnamecheckin").focus();


            return false
        }



        const myPckartUser = localStorage.getItem('MYPCKARTUSER'); if (!myPckartUser) { toast.error("Please Login First.."); return false };
        const inputValue = document.getElementById("inputname").value.trim();
        if (inputValue === "") {
            setIsLoading(false);
            toast.error("Please Enter Mobile No")
            return false
        }

        try {
            if (document.getElementById("pagemode").innerHTML == ' Reservation [ NEW ]') {
                await axios.post(`${hostlink}/reservationBulk`, roomdetail)
                    .then((Response) => {
                        setIsLoading(false);
                        toast("Save Sucessfull")
                        var ld = {
                            ...logdetail,
                            newdesc: `Res No : ${Response.data}, Mob No : ${gmob}, Name : ${gname},  Advance : ${advance}`,
                            operation: "New"
                        }
                        setlogdetail(ld);
                        saveLog(ld)
                        setTimeout(() => {
                            window.location.assign("/Reservation_view")
                        }, 1000);
                    })
                    .catch(() => {
                        setIsLoading(false);
                        toast.error("Reservation already exists for this Date.")
                        document.getElementById("cmdsave").disabled = false
                    })
            } else {
                await axios.put(`${hostlink}/reservationBulkEdit`, roomdetail)
                    .then((Response) => {
                        setIsLoading(false);
                        toast("Save Sucessfull")
                        setTimeout(() => {
                            var ld = {
                                ...logdetail,
                                newdesc: `Res No : ${id}, Mob No : ${gmob}, Name : ${gname} , Advance : ${advance}`,
                                operation: "Edit"
                            }
                            setlogdetail(ld);
                            saveLog(ld)
                            window.location.assign("/Reservation_view")
                        }, 1000);
                    })
                    .catch(() => {
                        setIsLoading(false);
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            }
        } catch (error) {
        }
    }
    const [savedata, setSavedata] = useState({
        id: "",
        resdate: today.toISOString().split('T')[0],
        chdate: today.toISOString().split('T')[0],
        gmob: "",
        noofroom: "",
        refrence: "",
        choutdate: "",
        gname: "",
        billtocompany: 0,
        billtocompanyname: "",
        add1: "",
        add2: "",
        email: "",
        rtype: "",
        advance: "",
        discount: "",
        remarks: "",
        cname: "",
        stday: 1,
        mg: "",
        rent: 0,
        billamt: 0,
        fg: "",
        cg: "",
        paidby: "",
        documentno: "",
        gstno: "",
        shopresno: 0,
        shopid: usershopid,
        narration: "",
        roomtypename: "",
        acccode: 0
    })

    const { id, documentno, acccode, shopresno, gsttype, gstno, roomtypename, resdate, noofroom, rent, chdate, billamt, gmob, gname, add1, add2, email, rtype, advance, discount, remarks, refrence, billtocompany, billtocompanyname, cname, stday, mg, fg, cg, paidby, choutdate } = savedata;

    const [gridDataData, setgridDataData] = useState({
        gridcheckindate: "",
        gridcheckindate: ""
    })
    const { gridcheckindate, gridcheckOutdate } = gridDataData;
    useEffect(() => {
        if (chdate && stday > 0) {
            const newChoutdate = new Date(chdate);
            newChoutdate.setDate(newChoutdate.getDate() + parseInt(stday, 10));
            setSavedata((prevData) => ({
                ...prevData,
                choutdate: newChoutdate.toISOString().split("T")[0],
            }));
            rentcalc();
        }

    }, [chdate, stday]);

    useEffect(() => {
        rentcalc();
    }, [gsttype]);
    // Update stay days when either chdate or choutdate changes
    useEffect(() => {
        if (choutdate) {
            const startDate = new Date(chdate);
            const endDate = new Date(choutdate);
            const dayDifference = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
            if (dayDifference >= 0) {
                setSavedata((prevData) => ({
                    ...prevData,
                    stday: dayDifference || 1, // Ensure at least 1 day
                }));
            }
            rentcalc();
        }
    }, [choutdate]);


    const [Billgstperc, setBillgstperc] = useState(0);
    const [BillgstAmt, setBillgstAmt] = useState(0);
    const [DiscAmt, setDiscAmt] = useState(0);
    const [BillAmt, setBillAmt] = useState(0);
    const [taxableAmountRate, settaxableAmountRate] = useState(0);

    const rentcalc = () => {

    };

    useEffect(() => {
        if (usershopid !== 0) {
            const url =
                selectedFilter === null
                    ? `${hostlink}/${usershopid}/reservationViewNew`
                    : `${hostlink}/${usershopid}/reservationviewFilterReportNew/${blfromdate}/${bltodate}/${selectedFilter}`;

            axios.get(url)
                .then((Response) => {
                    setData(Response.data);
                })
                .catch(() => {
                    console.log("Data Not Fetched");
                });
        }

    }, [refreshData, usershopid, blfromdate, bltodate, selectedFilter])

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const exportToExcel = () => {
            const ws = XLSX.utils.table_to_sheet(tableRef.current);
            const boldAndColorStyle = {
                font: { bold: true },
                fill: { fgColor: { rgb: "FFFF00" } }
            };
            const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
            for (let c = 0; c <= headerRow; c++) {
                const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
                if (ws[cellAddress].v === 'Sr No') {
                    ws[cellAddress].s = boldAndColorStyle;
                    for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                        const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                        if (ws[dataCellAddress]) {
                            ws[dataCellAddress].s = boldAndColorStyle;
                        }
                    }
                    break;
                }
            }
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
            XLSX.writeFile(wb, 'Reservation Data.xlsx');
        };

    return (
        <div className='p-1'>

            {/* ----------------------- */}
            <div className='container p-2' id='dataviewgrid'>
                <div className='row p-1'>
                    <div className='col-md-3 p-1  align-self-center'>
                        <h5 className='font-monospace'><i class="fa-solid fa-door-open"></i> Reservation Details</h5>
                    </div>
                    <div className='col-md-3 rounded align-self-center' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)", height: "40px" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                    </div>
                    <div className='col-md-5 align-self-end ' >
                        <div className="row">
                            <div className="col-6 text-end">
                                <input
                                    id="reservationFilter"
                                    type="checkbox"
                                    style={{ marginRight: "5px" }}
                                    checked={selectedFilter === 0} // Bind to state
                                    onChange={() => handleFilterChange(0)} // Handle change
                                />
                                Reservation
                            </div>
                            <div className="col-6 text-start">
                                <input
                                    id="checkinFilter"
                                    type="checkbox"
                                    style={{ marginRight: "5px" }}
                                    checked={selectedFilter === 1} // Bind to state
                                    onChange={() => handleFilterChange(1)} // Handle change
                                />
                                Check-In
                            </div>
                        </div>

                        <div className="row" >
                            <div className="col-12" style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                <span className="f-size-on-mobile align-self-end">
                                    <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
                                </span>
                                <span> <input type="date" id="blfromdate" name="blfromdate" onChange={(e) => { bldatechange(e); }} value={blfromdate} className="form-control" /></span>


                                <span className="f-size-on-mobile align-self-end">
                                    <label htmlFor="bltodate" className="form-label text-center">&nbsp; To : &nbsp;</label>
                                </span>
                                <span>
                                    <input type="date" id="bltodate" name="bltodate" onChange={(e) => { bldatechange(e); }} value={bltodate} className="form-control" />
                                </span>

                                <span className='p-1'>
                                <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>
                                </span>
                            </div>
                        </div>


                    </div>
                    <div className='col-md-1 text-end  align-self-center'>
                        {/* {(userType === 0 || appState[1]?.[0]?.reservation?.charAt(1) === '1') ? (<button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp;New </button>) : null} */}
                    </div>
                    <div style={{ maxHeight: "100vh", overflowY: "auto", overflowX: "auto", }} className="col-12">
                        <table className="table table-hover table-striped small-font" style={{ tableLayout: "fixed", width: "100%" }} ref={tableRef}>
                            <thead>
                                <tr>
                                    <th style={{ width: "70px", textAlign: "center", padding: "5px" }}>Res No</th>
                                    <th style={{ width: "120px", textAlign: "center", padding: "5px" }}>Guest Name</th>
                                    <th style={{ width: "120px", textAlign: "center", padding: "5px" }}>Check-in Date</th>
                                    <th style={{ width: "200px", textAlign: "center", padding: "5px" }}>Reservation Date & Time</th>
                                    <th style={{ width: "100px", textAlign: "center", padding: "5px" }}>No Of Days</th>
                                    <th style={{ width: "200px", textAlign: "center", padding: "5px" }}>Expected CheckOut Date</th>
                                    <th style={{ width: "100px", textAlign: "center", padding: "5px" }}>No Of Pax</th>
                                    <th style={{ width: "100px", textAlign: "center", padding: "5px" }}>No Of Room</th>
                                    <th style={{ width: "200px", textAlign: "center", padding: "5px" }}>Room Category</th>
                                    <th style={{ width: "100px", textAlign: "center", padding: "5px" }}>Plan</th>
                                    <th style={{ width: "100px", textAlign: "center", padding: "5px" }}>N.A</th>
                                    <th style={{ width: "200px", textAlign: "center", padding: "5px" }}>Referred By</th>
                                    <th style={{ width: "200px", textAlign: "center", padding: "5px" }}>Referred Mobile No</th>
                                    <th style={{ width: "200px", textAlign: "center", padding: "5px" }}>Block Room No</th>
                                    <th style={{ width: "100px", textAlign: "center", padding: "5px" }}>UserName</th>
                                    <th style={{ width: "200px", textAlign: "center", padding: "5px" }}>Advance Amount</th>
                                    <th style={{ width: "200px", textAlign: "center", padding: "5px" }}>Reserv.Status</th>
                                    <th style={{ width: "200px", textAlign: "center", padding: "5px" }}>Updated On</th>
                                    <th style={{ width: "200px", textAlign: "center", padding: "5px" }}>Updated By</th>
                                    <th style={{ width: "400px", textAlign: "center", padding: "5px" }}>Remark</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.map((res, x) => (
                                    <tr key={x} style={{ verticalAlign: 'middle' }}>
                                        <td style={{ minWidth: "100px", padding: "5px", textAlign: "center" }}>{res.shopresno}</td>
                                        <td style={{ minWidth: "100px", padding: "5px", textAlign: "center" }}>{res.gname}</td>
                                        <td style={{ minWidth: "100px", padding: "5px", textAlign: "center" }}>{res.chdate ? new Date(res.chdate).toLocaleDateString('en-GB') : "-"}</td>
                                        <td style={{ minWidth: "180px", maxWidth: "400px", textAlign: "center" }}>{res.resdate ? new Date(res.resdate).toLocaleDateString('en-GB') : "No data"} {res.restime}</td>
                                        <td style={{ minWidth: "100px", padding: "5px", textAlign: "center" }}>{res.stday}</td>
                                        <td style={{ minWidth: "120px", maxWidth: "400px", textAlign: "center" }}>{res.choutdate ? new Date(res.choutdate).toLocaleDateString('en-GB') : "No data"}</td>
                                        <td style={{ minWidth: "100px", padding: "5px", textAlign: "center" }}>{Number(res.mg) + Number(res.fg) + Number(res.cg)}</td>
                                        <td style={{ minWidth: "100px", padding: "5px", textAlign: "center" }}>{res.noofroom}</td>
                                        <td style={{ minWidth: "180px", maxWidth: "400px", textAlign: "center" }}>{res.roomtypename}</td>
                                        <td style={{ minWidth: "100px", padding: "5px", textAlign: "center" }}>-</td>
                                        <td style={{ minWidth: "100px", padding: "5px", textAlign: "center" }}>{res.amount}</td>
                                        <td style={{ minWidth: "180px", maxWidth: "400px", textAlign: "center" }}>{res.refrence}</td>
                                        <td style={{ minWidth: "180px", maxWidth: "400px", textAlign: "center" }}>-</td>
                                        <td style={{ minWidth: "180px", maxWidth: "400px", textAlign: "center" }}>-</td>
                                        <td style={{ minWidth: "100px", padding: "5px", textAlign: "center" }}>Admin</td>
                                        <td style={{ minWidth: "180px", maxWidth: "400px", textAlign: "center" }}>{res.advance}</td>
                                        <td style={{ minWidth: "180px", maxWidth: "400px", textAlign: "center" }}>{res.status === 1 ? 'Canceled' : 'Confirmed'}</td>
                                        <td style={{ minWidth: "180px", maxWidth: "400px", textAlign: "center" }}>-</td>
                                        <td style={{ minWidth: "180px", maxWidth: "400px", textAlign: "center" }}>-</td>
                                        <td style={{ minWidth: "100px", padding: "5px", maxWidth: "400px", textAlign: "center" }}>{res.remarks}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            {isLoading && <Loading />}

            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default Reservation_view
