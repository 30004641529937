import { UserLoginDetails } from '../Hostlink/UserLoginDetails'
import React, { useEffect, useState, useContext, useRef } from 'react'
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import Loading from '../Component/Loading';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const Rpt_Room_availability = () => {
    const [selectedYear, setSelectedYear] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopdeptcode = appState?.[0]?.deptcode || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;
    const today = new Date();
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        deptcode: usershopdeptcode,
        status: 0
    });
    const { fromdate, todate, status, deptcode } = frmdata;
    const queryCondition = usershopidref == 0
        ? `${usershopid}`
        : `${usershopidref}`;

    const [deptdata, setdeptdata] = useState([])
    const [data, setData] = useState([]);



    const tableRef = useRef(null);

    const loaddata = async () => {
        setIsLoading(true);
        try {
            const today = new Date();
            const year = selectedYear.getFullYear(); // Extracts the year from today's date
            const billResponse = await axios.get(`${hostlink}/${usershopid}/yearly-checkins/${year}`);
            console.log("API Response:", billResponse.data); // Debugging log

            // Convert object to an array of { month, days }
            let formattedData = Object.entries(billResponse.data).map(([month, days]) => ({
                month,
                days
            }));

            setData(formattedData); // Now data is an array
        } catch (error) {
            console.error("Error loading data:", error);
            setData([]);
        } finally {
            setIsLoading(false);
        }
    };


    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Room availability Report.xlsx');
    };

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-6 align-self-center text-center'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-object-group"></i> <span id='pagemode'> Room availability Report</span></h5>
                        <p>Date : {fromdate ? new Date(fromdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</p>
                    </div>

                    <div className='col-md-2 align-self-center text-center'>
                        Year
                        <DatePicker
                            selected={selectedYear}
                            onChange={(date) => setSelectedYear(date)}
                            showYearPicker
                            dateFormat="yyyy"
                            className="form-control align-self-end"
                        />
                    </div>
                    <div className='col-md-3 align-self-center text-end'>
                        <button onClick={() => { loaddata(1); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show</button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>
                    </div>
                </div>
            </div>
            {/* maxHeight: "400px", */}
            <p></p>
            <div className='container-fluid ' style={{ overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='table-info shadow rounded border border-1' >
                        <tr >
                            <td>Month</td><td>1</td> <td>2</td> <td>3</td> <td>4</td> <td>5</td> <td>6</td> <td>7</td> <td>8</td> <td>9</td> <td>10</td><td>11</td><td>12</td> <td>13</td>
                            <td>14</td> <td>15</td> <td>16</td> <td>17</td> <td>18</td> <td>19</td> <td>20</td> <td>21</td> <td>22</td> <td>23</td> <td>24</td> <td>25</td>
                            <td>26</td> <td>27</td> <td>28</td> <td>29</td> <td>30</td> <td>31</td>
                        </tr>

                    </thead>
                    <tbody>
                        {data.length > 0 ? (
                            data.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.month}</td>
                                    {item.days.map((day, dayIndex) => (
                                        <td key={dayIndex}>{day}</td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="32" className="text-center">No data available</td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>
            {isLoading && <Loading />}
        </div>
    )
}

export default Rpt_Room_availability