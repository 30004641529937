import { UserLoginDetails } from '../Hostlink/UserLoginDetails'
import React, { useEffect, useState, useContext, useRef } from 'react'
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import Loading from '../Component/Loading';

const Check_in_Commission = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopdeptcode = appState?.[0]?.deptcode || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;
    
    const queryCondition = usershopidref == 0
    ? `${usershopid}`
    : `${usershopidref}`;
    const [RoomType, setRoomType] = useState([]);
    const [Room, setRoom] = useState([]);
    const [deptdata, setdeptdata] = useState([])
    const [data, setData] = useState([]);
    const today = new Date();
    
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        deptcode:usershopdeptcode,
        status: 0
    });
    const [totalSums, setTotalSums] = useState({});

    const { fromdate, todate, status,deptcode } = frmdata;
    const tableRef = useRef(null);
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };
    const loaddata = async (e) => {
        setIsLoading(true)
        try {
            
                const billResponse = await axios.get(`${hostlink}/checkinCommission/${usershopid}/${fromdate}/${todate}`);
                const billData = billResponse.data;
                setData(billResponse.data)            
                setIsLoading(false)
        } catch (error) {
            console.log("Data Not Found...");
        }
    };
    useEffect(()=>{
        axios.get(`${hostlink}/${queryCondition}/deptmas`)
        .then((Response) => { setdeptdata(Response.data) })
    },[usershopid])

    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Reservation Report.xlsx');
    };

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-6 align-self-center'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-door-open"></i> <span id='pagemode'> Check-in Commission Report</span></h5>
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="fromdate" className="form-label">From Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="todate" className="form-label">To Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" />
                    </div>
                    
                    <div className='col-md-12 align-self-center text-end mt-2'>
                       
                        <button onClick={() => { loaddata(1); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show</button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>
                       
                    </div>
                </div>
            </div>


            <br />
            
            <div className='container-fluid' style={{  overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center' >
                        <tr >
                            <th className='text-start' scope="col">Ch Date</th>
                            <th className='text-start' scope="col">ch Time</th>
                            <th className='text-start' scope="col">Reference</th>
                            <th className='text-start' scope="col">Mobile No</th>
                            <th className='text-start' scope="col">Vehicle No</th>
                            <th className='text-end' scope="col">Amount</th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
  {(() => {
    let runningTotal = 0;
    let runningTotaltotal = 0;
    let previousReference = null;
    let rows = [];

    data.forEach((res, x) => {
      // Check if the reference has changed (first row is skipped)
      if (previousReference !== null && previousReference !== res.reference) {
        // Push the subtotal row before starting a new group
        rows.push(
          <tr key={`subtotal-${x}`} className="table-warning">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="fw-bold text-end">Total:</td>
            <td className="fw-bold text-end">{runningTotal.toFixed(2)}</td>
          </tr>
        );

        // Reset runningTotal for the new reference group
        runningTotal = 0;
      }

      // Add current row amount to the totals
      runningTotal += res.referencecomamt;
      runningTotaltotal += res.referencecomamt;

      // Push the actual row
      rows.push(
        <tr key={x}>
          <td className="text-start">
            {res.indate
              ? new Date(res.indate).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                })
              : "-"}
          </td>
          <td className="text-start">{res.intime}</td>
          <td className="text-start">{res.reference}</td>
          <td className="text-start">{res.referencemobno}</td>
          <td className="text-start">{res.referencevehno}</td>
          <td className="text-end">{res.referencecomamt.toFixed(2)}</td>
        </tr>
      );

      // Update the previous reference for the next iteration
      previousReference = res.reference;
    });

    // Add the last subtotal row after the loop
    if (data.length > 0) {
      rows.push(
        <>
          <tr key="final-subtotal" className="table-warning">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="fw-bold text-end">Total:</td>
            <td className="fw-bold text-end">{runningTotal.toFixed(2)}</td>
          </tr>
          <tr key="grand-total" className="table-primary">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="fw-bold text-end">Grand Total:</td>
            <td className="fw-bold text-end">{runningTotaltotal.toFixed(2)}</td>
          </tr>
        </>
      );
    }

    return rows;
  })()}
</tbody>


                </table>              
            </div>
            {isLoading && <Loading />}
        </div>
    )
}

export default Check_in_Commission