import { UserLoginDetails } from '../Hostlink/UserLoginDetails'
import React, { useEffect, useState, useContext, useRef } from 'react'
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import Loading from '../Component/Loading';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const Rpt_Room_availability_Roomtype = () => {
    const [selectedYear, setSelectedYear] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopdeptcode = appState?.[0]?.deptcode || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;
    const today = new Date();
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        deptcode: usershopdeptcode,
        status: 0
    });
    const { fromdate, todate, status, deptcode } = frmdata;
    const queryCondition = usershopidref == 0
        ? `${usershopid}`
        : `${usershopidref}`;

    const [deptdata, setdeptdata] = useState([]);
    const [data, setData] = useState([]);

    const tableRef = useRef(null);

    const loaddata = async () => {
        setIsLoading(true);
        try {
            const today = new Date();
            const year = selectedYear.getFullYear(); // Extracts the year from today's date
            const billResponse = await axios.get(`${hostlink}/${usershopid}/yearly-checkins-roomtype/${year}`);
            console.log("API Response:", billResponse.data); // Log to confirm structure

            // Convert object to an array of { month, roomTypes }
            let formattedData = Object.entries(billResponse.data).map(([monthIndex, roomTypes]) => {
                console.log(`Month Index: ${monthIndex}`); // Log the index to verify the API structure

                // Calculate the total for each day and the monthly total
                let totalMonthlyAvailability = 0; // Total rooms available for the month
                const dailyTotals = Array(31).fill(0); // Initialize an array to store daily totals

                // Iterate over room types and sum availability for each day
                Object.entries(roomTypes).forEach(([roomType, availability]) => {
                    availability.forEach((rooms, dayIndex) => {
                        dailyTotals[dayIndex] += rooms; // Add rooms to the respective day
                        totalMonthlyAvailability += rooms; // Add to the monthly total
                    });
                });

                return {
                    month: monthIndex, // Directly use the month name from the API response
                    roomTypes: Object.entries(roomTypes).map(([roomType, availability]) => ({
                        roomType,
                        availability
                    })),
                    monthlyTotal: totalMonthlyAvailability, // Total rooms available across all room types for the month
                    dailyTotals: dailyTotals // Array of total availability for each day in the month
                };
            });

            // Log the formatted data to check the structure
            console.log("Formatted Data:", formattedData);

            setData(formattedData); // Now data is an array with monthly and daily totals
        } catch (error) {
            console.error("Error loading data:", error);
            setData([]);
        } finally {
            setIsLoading(false);
        }
    };




    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Room availability Report.xlsx');
    };

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-6 align-self-center text-center'>
                        <h5 className='font-monospace text-primary'>
                            <i className="fa-solid fa-object-group"></i>
                            <span id='pagemode'> Room availability Report - (Room Type)</span>
                        </h5>
                        <p className='text-muted'>
                            Date: {fromdate ? new Date(fromdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}
                        </p>
                    </div>


                    <div className='col-md-2 align-self-center text-center'>
                        Year
                        <DatePicker
                            selected={selectedYear}
                            onChange={(date) => setSelectedYear(date)}
                            showYearPicker
                            dateFormat="yyyy"
                            className="form-control align-self-end"
                        />
                    </div>
                    <div className='col-md-4 align-self-center text-end'>

                        <button onClick={() => { loaddata(1); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}>
                            <i className="fa-solid fa-magnifying-glass-chart"></i> Show
                        </button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}>
                            <i className="fa-solid fa-table"></i> Excel
                        </button>
                    </div>
                </div>
            </div>

            <p></p>
            <div className='container-fluid' style={{ overflow: "auto" }}>
                <table className="table table-hover table-striped" ref={tableRef} style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                    <thead className='table-info shadow rounded border border-1'>
                        <tr className=' border-1'>
                            <th className=' border-1'>Month</th>
                            <th className=' border-1'>Room Type</th>
                            {Array.from({ length: 31 }, (_, i) => (
                                <th className=' border-1  text-center' key={i}>{i + 1}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 ? (
                            data.map(({ month, roomTypes }, index) => {
                                // Initialize an array to store the daily totals (one for each of the 31 days)
                                const dailyTotals = new Array(31).fill(0);

                                // Sum availability for each day across all room types
                                roomTypes.forEach(({ availability }) => {
                                    availability.slice(0, 31).forEach((day, dayIndex) => {
                                        // Add the availability for each day to the corresponding index in dailyTotals
                                        dailyTotals[dayIndex] += day || 0; // Ensure to add 0 for invalid data
                                    });
                                });

                                return (
                                    <React.Fragment key={index}>
                                        {/* Render rows for each room type */}
                                        {roomTypes.map(({ roomType, availability }, roomIndex) => (
                                            <tr key={roomIndex}>
                                                {/* Render month column */}
                                                {roomIndex === 0 && (
                                                    <td rowSpan={roomTypes.length}><b style={{ color: "blue" }}>{month}</b></td>
                                                )}

                                                {/* Render room type column */}
                                                <td className=' border-1 '>{roomType}</td>

                                                {/* Render availability for each day of the month */}
                                                {Array.isArray(availability) ? (
                                                    availability.slice(0, 31).map((day, dayIndex) => (
                                                        <td className=' border-1 text-center' key={dayIndex}>{day}</td>
                                                    ))
                                                ) : (
                                                    <td colSpan="31" className="text-center">Invalid Data</td>
                                                )}
                                            </tr>
                                        ))}

                                        {/* Render daily totals row for the month */}
                                        <tr >
                                        <td className='bg-primary text-bg-danger' colSpan="2"><b>{month} - Daily Totals</b></td>
                                            {dailyTotals.map((total, dayIndex) => (
                                                <td className='bg-primary text-bg-danger border-1  text-center' key={dayIndex}>{total}</td>
                                            ))}
                                        </tr>
                                    </React.Fragment>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="33" className="text-center">No data available</td>
                            </tr>
                        )}
                    </tbody>




                </table>
            </div>
            {isLoading && <Loading />}
        </div>
    );
};

export default Rpt_Room_availability_Roomtype;
