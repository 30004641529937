import React, { useEffect, useState, useContext } from 'react'
import hostlink from '../Hostlink/hostlink';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from './Loading';

const CheckOut_Details = () => {
    const [isModalOpenFoodCh, setIsModalOpenFoodCh] = useState(false);
    const handleCloseModalFoodCh = () => { setIsModalOpenFoodCh(false); };
    var billgst;
    const [GST_P_1, setGST_P_1] = useState(0);
        const [GST_P_2, setGST_P_2] = useState(0);
        const [GST_P_3, setGST_P_3] = useState(0);
        const [GST_P_4, setGST_P_4] = useState(0);
    const handleNoClickFoodCh = () => { console.log('User clicked No'); handleCloseModalFoodCh(); };
    const [AccCodeBTC, setAccCodeBTC] = useState([]);
    const [RoomDetails, setRoomDetails] = useState([]);
    const [AccCode, setAccCode] = useState([]);
    const [cmbAccdataBank, setcmbAccdataBank] = useState([]);
    const today = new Date();
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const [isLoading, setIsLoading] = useState(false);
    const usershopid = appState?.[0]?.id || 0;
    const [DataSeacrh, setDataSeacrh] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [selectAll, setSelectAll] = useState(false);
    const linkroomReservationNo = queryParams.get('id');
    const [isModalOpenSearchRes, setIsModalOpenSearchRes] = useState(true);
    const handleCloseModalSearchRes = () => {
        setIsModalOpenSearchRes(false);
        window.location.assign(`/CheckOut`);
    };
    const [selectedItems, setSelectedItems] = useState([]);
    const handleCheckboxChange = (itemId) => {
        setSelectedItems(prevSelectedItems =>
            prevSelectedItems.includes(itemId)
                ? prevSelectedItems.filter(id => id !== itemId)
                : [...prevSelectedItems, itemId]
        );
    };
    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(DataSeacrh.map(item => item[8])); // Assuming each item has a unique 'id'
        }
        setSelectAll(!selectAll);
    }
    const handleCheckout = async () => {
        if (!selectedItems || selectedItems.length === 0) {
            setIsModalOpenSearchRes(true);
            toast.error("Please Select Room...")
            return false; // Exit the function if selectedItems is blank
        }

        if (usershopid != 0) {
            const sqlQuery = `
            select checkinroomdetails.*,checkinmas.* from checkinroomdetails,checkinmas 
            where checkinmas.id = checkinroomdetails.chid and checkinmas.id = ${linkroomReservationNo} 
            and checkinmas.shopid =${usershopid} and checkinroomdetails.rcode in (${selectedItems})`;
            const encodedSqlQuery = encodeURIComponent(sqlQuery);
            console.log(`${sqlQuery}`)
            axios.get(`${hostlink}/api/executeQuery?sqlQuery=${encodedSqlQuery}`)
                .then((Response) => {
                    setRoomDetails(Response.data);
                    setBilldata({
                        ...billdata,
                        guestmob: Response.data[0][31],
                        guestname: Response.data[0][26],
                        guestadd1: Response.data[0][15],
                        guestadd2: Response.data[0][16],
                        guestdocno: Response.data[0][22],
                        guestemail: Response.data[0][24],
                        guestgstno: Response.data[0][27],
                        guestcompanyname: Response.data[0][20],
                        reference: Response.data[0][44],
                        blremarks: "",
                        billtocompany: Response.data[0][48],
                        
                    });
                    console.log("Response[0][0].data");
                    console.log(Response.data[0][0]);
                })
                .catch(() => { });
            setIsModalOpenSearchRes(false);
            console.log(selectedItems)
        }

    };
    const [billdata, setBilldata] = useState({
        id: "",
        checkindate: "",
        reference: "",
        checkoutdate: today.toISOString().split('T')[0],
        bldate: today.toISOString().split('T')[0],
        roomcode: "",
        billtocompany: 0,
        pay1amt: 0,
        pay2amt: 0,
        foodamount: 0,
        restbilltrf: 0,
        foodpackagename: "",
        foodpackagebeforegst: 0,
        foodpackagedays: 0,
        foodpackagegst: 0,
        foodpackagegsttype: 0,
        guestroomname: "",
        roomrent: 0,
        extrabed: 0,
        extrabedch: 0,
        discountperc: 0,
        adddiscountamt: 0,
        additionalcharge: 0,
        advanceamount: 0,
        blpaidby: "",
        guestmob: "",
        guestname: "",
        guestadd1: "",
        guestadd2: "",
        guestdocno: "",
        guestemail: "",
        guestgstno: "",
        guestcompanyname: "",
        blremarks: "",
        bltotaldays: "",
        bltotal: "",
        blroomrent: "",
        discountpercamt: "",
        blgstperc: "",
        guestroomname: "",
        blgstamt: "",
        blamt: "",
        netpayamt: "",
        checkinid: "",
        shopresno: 0,
        shopid: usershopid,
        narration: "",
        roomdescription: "",
        roomdescriptioncode: "",
        roomserviceamt: 0,
        roundoff: 0,
        acccode: 0,
        acccode2: 0,
        pay1amt: 0,
        pay2amt: 0,
    })
    const { checkinid, checkindate, checkoutdate, acccode, acccode2, pay1amt, pay2amt, roomcode, roomrent, extrabed, extrabedch, roomserviceamt, discountperc, adddiscountamt, foodamount, additionalcharge, advanceamount, blpaidby, guestmob, guestname, guestadd1, guestadd2, guestdocno, guestemail, guestgstno, guestcompanyname, reference, billtocompany, blremarks, bltotaldays, bltotal, blroomrent, discountpercamt, blgstperc, blgstamt, blamt, netpayamt, restbilltrf, foodpackagedays, foodpackagebeforegst, foodpackagegst, foodpackagegsttype, foodpackagename } = billdata;

    const [Total_billdata, setTotal_billdata] = useState({
        bill_TotalDays: "",
        bill_Discount: "",
        bill_Total: "",
        bill_GSTAmount: "",
        bill_AdditionalCharge: "",
        bill_AdditionalDiscount: "",
        bill_RoomService: "",
        bill_RoomTransferBill: "",
        bill_FoodCharge: "",
        bill_Roundoff: "",
        bill_Billamount: "",
        bill_Advance: "",
        bill_NetPayableAmt: "",
    })
    const { bill_TotalDays,bill_Discount,bill_Total,bill_GSTAmount,bill_AdditionalCharge, bill_AdditionalDiscount,bill_RoomService, bill_RoomTransferBill,bill_FoodCharge,bill_Roundoff,bill_Billamount,bill_Advance,bill_NetPayableAmt  } = Total_billdata;

    const onChangevaluebill = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setBilldata({ ...billdata, [name]: sanitizedValue });
    }
    useEffect(() => {
        if (usershopid != 0) {
            axios.get(`${hostlink}/${usershopid}/accmas/2`).then((ResponseAcc) => { setAccCodeBTC(ResponseAcc.data) })
            axios.get(`${hostlink}/${usershopid}/accmas/0`).then((Response) => { setAccCode(Response.data) });
            axios.get(`${hostlink}/${usershopid}/accmas/1`).then((Response) => { setcmbAccdataBank(Response.data); }).catch((Response) => { "data Not Found" })
            const sqlQuery = `
                select checkinroomdetails.*,checkinmas.* from checkinroomdetails,checkinmas 
                where checkinmas.id = checkinroomdetails.chid and checkinmas.id = ${linkroomReservationNo} 
                and checkinmas.shopid =${usershopid}`;
            const encodedSqlQuery = encodeURIComponent(sqlQuery);
            axios.get(`${hostlink}/api/executeQuery?sqlQuery=${encodedSqlQuery}`)
                .then((Response) => {
                    setDataSeacrh(Response.data);
                    console.log(Response.data);
                })
                .catch(() => { });
        }
    }, [usershopid])
    return (
        <div>
            <div className='container'>
                <div className="row shadow rounded">
                    <div className="col-9">
                        <div className='container p-1'>
                            <div className='row bg-info shadow rounded mt-1 align-self-end p-1' style={{ backgroundImage: 'linear-gradient(to right, #6AF4D7, #ff9999, #6AF4D7)', color: 'white', padding: '20px' }}>
                                <div className='col-md-8 align-self-end ' >
                                    <h5 className='font-monospace text-primary text-black'> <i class="fa-regular fa-address-card"></i> <span id='pagemodeaaa' > Guest Details On Bill</span></h5>
                                </div>
                                <div className='col-md-2 align-self-end text-end'>
                                    <label htmlFor="todate" className="form-label font-monospace text-black">Bill Date:</label>
                                </div>
                                <div className='col-md-2'>
                                    <input type="date" name='checkoutdate' onChange={(e) => { onChangevaluebill(e) }} value={checkoutdate} id="checkoutdate" className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div class="col-md-2">
                                <label className='font-monospace' class="form-label">Mob No</label>
                                <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestmob' value={guestmob} class="form-control" /> <br />
                            </div>
                            <div class="col-md-4">
                                <label className='font-monospace' class="form-label">Guest Name</label>
                                <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestname' value={guestname} class="form-control" /> <br />
                            </div>
                            <div class="col-md-3">
                                <label className='font-monospace' class="form-label">Add 1</label>
                                <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestadd1' value={guestadd1} class="form-control" /> <br />
                            </div>
                            <div class="col-md-3">
                                <label className='font-monospace' class="form-label">Add 2</label>
                                <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestadd2' value={guestadd2} class="form-control" /> <br />
                            </div>
                            <div className="col-md-2">
                                <label className='font-monospace' class="form-label">Document No</label>
                                <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestdocno' value={guestdocno} class="form-control" /> <br />
                            </div>
                            <div className="col-md-4">
                                <label className='font-monospace' class="form-label">Select Document</label>
                                <input type="file" class="form-control" id="documentpath" />
                            </div>
                            <div className="col-md-3">
                                <label className='font-monospace' class="form-label">Email</label>
                                <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestemail' value={guestemail} class="form-control" /> <br />
                            </div>
                            <div className="col-md-3">
                                <label className='font-monospace' class="form-label">Company Name</label>
                                <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestcompanyname' value={guestcompanyname} class="form-control" /> <br />
                            </div>

                            <div className="col-md-3">
                                <label className='font-monospace' class="form-label">GST No</label>
                                <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='guestgstno' value={guestgstno} class="form-control" /> <br />
                            </div>
                            <div className="col-md-4">
                                <label className='font-monospace' class="form-label">Reference by</label>
                                <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='reference' value={reference} class="form-control" /> <br />
                            </div>
                            <div className="col-md-5">
                                <label className='font-monospace' class="form-label">Remarks</label>
                                <input type="text" onChange={(e) => { onChangevaluebill(e) }} name='blremarks' value={blremarks} class="form-control" /> <br />
                            </div>
                            <div class="col-md-4">
                                <label for="inputState" class="form-label">BTC</label>
                                <select id="billtocompany" onChange={(e) => { onChangevaluebill(e) }} name='billtocompany' value={billtocompany} class="form-select">
                                    <option selected key={0} value={0}>NA</option>
                                    {AccCodeBTC.map((x) => {
                                        return (
                                            <option key={x.id} value={x.id}> {x.accname} </option>
                                        )
                                    })}
                                </select>
                            </div>
                            <p></p>
                            <h5 className='font-monospace text-primary'> <i class="fa-solid fa-circle-info"></i> <span > Details</span></h5>

                            <div className="col-md-2">
                                <label className='font-monospace' class="form-label">Discount %</label>
                                <input type="number" onChange={(e) => { onChangevaluebill(e); }} name='discountperc' value={discountperc} class="form-control" /> <br />
                            </div>
                            <div className="col-md-2">
                                <label className='font-monospace' class="form-label">Flat Discount</label>
                                <input type="number" onChange={(e) => { onChangevaluebill(e) }} name='adddiscountamt' value={adddiscountamt} class="form-control" /> <br />
                            </div>
                            <div className="col-md-2">
                                <label className='font-monospace' class="form-label">Additional Charge</label>
                                <input type="number" onChange={(e) => { onChangevaluebill(e) }} name='additionalcharge' value={additionalcharge} class="form-control" /> <br />
                            </div>
                            <div className="col-md-3">
                                <label className='font-monospace' class="form-label">Advance</label>
                                <input type="number" onChange={(e) => { onChangevaluebill(e) }} name='advanceamount' value={advanceamount} class="form-control" /> <br />
                            </div>
                            <div className="col-md-3">

                            </div>


                            <div class="col-md-3">
                                <label for="inputState" class="form-label">MOP - 1 </label>
                                <select id="acccode" onChange={(e) => { onChangevaluebill(e) }} name='acccode' value={acccode} class="form-select">
                                    <option selected key={0} value={0}>Choose...</option>
                                    {AccCode.map((x) => {
                                        return (
                                            <option key={x.id} value={x.id}> {x.accname} </option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="col-md-2">
                                <label className='font-monospace' class="form-label">Amount</label>
                                <input type="number" onChange={(e) => { onChangevaluebill(e) }} name='pay1amt' value={pay1amt} class="form-control" /> <br />
                            </div>

                            <div class="col-md-3">
                                <label for="inputState" class="form-label">MOP - 2 </label>
                                <select id="acccode2" onChange={(e) => { onChangevaluebill(e) }} name='acccode2' value={acccode2} class="form-select">
                                    <option selected key={0} value={0}>Choose...</option>
                                    {cmbAccdataBank.map((x) => {
                                        return (
                                            <option key={x.id} value={x.id}> {x.accname} </option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="col-md-2">
                                <label className='font-monospace' class="form-label">Amount</label>
                                <input type="number" onChange={(e) => { onChangevaluebill(e) }} name='pay2amt' value={pay2amt} class="form-control" /> <br />
                            </div>
                        </div>

                    </div>
                    <div className="col-3">
                        <div class="col-md-12 shadow rounded " style={{ borderRadius: "50px 50px 50px " }}>
                            <br />
                            <h5 className='font-monospace text-primary text-center'>  <i class="fa-solid fa-right-from-bracket"></i>  <span id='pagemodesummary' > Summary</span></h5>
                            <div className='container border-1'>
                                <div className='row'>
                                    <div className='col-8  text-end'>
                                        <label className='font-monospace text-end ' class="form-label">Total Days :</label>
                                    </div>
                                    <div className='col-4 text-end'>
                                        <label className='font-monospace ' name='bltotaldays' value={bltotaldays} class="form-label">{bltotaldays}</label>
                                    </div>
                                    <div className='col-8  text-end'>
                                        <label className='font-monospace text-end ' class="form-label">Room Rent :</label>
                                    </div>
                                    <div className='col-4 text-end'>
                                        <label className='font-monospace ' name='blroomrent' value={blroomrent} class="form-label">{parseFloat(blroomrent).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, })}</label>
                                    </div>
                                    <div className='col-8   text-end'>
                                        <label className='font-monospace text-end ' class="form-label">Discount :</label>
                                    </div>
                                    <div className='col-4  text-end'>
                                        <label className='font-monospace ' name='discountpercamt' value={discountpercamt} class="form-label">{parseFloat(discountpercamt).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, })}</label>
                                    </div>

                                    <div className='col-8  bg-info-subtle text-end'>
                                        <label className='font-monospace text-end ' class="form-label">Total :</label>
                                    </div>
                                    <div className='col-4  bg-info-subtle text-end'>
                                        <label className='font-monospace ' name='bltotal' value={bltotal} class="form-label">{parseFloat(bltotal).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, })}</label>
                                    </div>
                                    <div className='col-8  text-end'>
                                        <label className='font-monospace text-end ' class="form-label">GST Amount : </label>
                                    </div>
                                    <div className='col-4 text-end'>
                                        <label className='font-monospace ' class="form-label">{parseFloat(blgstamt).toLocaleString('en-IN', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, })}</label>
                                    </div>
                                    <div className='col-8  text-end'>
                                        <label className='font-monospace text-end ' class="form-label">Additional Charge :</label>
                                    </div>
                                    <div className='col-4 text-end'>
                                        <label className='font-monospace ' class="form-label">{additionalcharge}</label>
                                    </div>
                                    <div className='col-8  text-end'>
                                        <label className='font-monospace text-end ' class="form-label">Additional Discount :</label>
                                    </div>
                                    <div className='col-4 text-end'>
                                        <label className='font-monospace ' class="form-label">{adddiscountamt}</label>
                                    </div>
                                    <div className='col-8  text-end'>
                                        <label className='font-monospace text-end ' class="form-label">Room Service :</label>
                                    </div>
                                    <div className='col-4 text-end'>
                                        <label className='font-monospace ' class="form-label">{roomserviceamt}</label>
                                    </div>
                                    <div className='col-8  text-end'>
                                        <label className='font-monospace text-end ' class="form-label">Room Transfer Bill :</label>
                                    </div>
                                    <div className='col-4 text-end'>
                                        <label className='font-monospace ' class="form-label">0.00</label>
                                    </div>
                                    <div className='col-8  text-end'>
                                        <label className='font-monospace text-end ' class="form-label">Food Charge : {foodpackagebeforegst}<br /> <span className='text-danger' onClick={() => { setIsModalOpenFoodCh(true) }}>{foodpackagedays} @day, Gst {foodpackagegst}%</span></label>
                                    </div>
                                    <div className='col-4 text-end'>
                                        <label className='font-monospace ' class="form-label">{foodamount}</label>
                                    </div>

                                    <div className='col-8 bg-warning-subtle text-end'>
                                        <label className='font-monospace text-end ' class="form-label">Round off :</label>
                                    </div>
                                    <div className='col-4 bg-warning-subtle text-end'>
                                        <label className='font-monospace' class="form-label">{Number(parseFloat(blamt).toFixed(0) - parseFloat(blamt)).toFixed(2)}</label>
                                    </div>

                                    <div className='col-8 bg-warning-subtle text-end'>
                                        <label className='font-monospace text-end ' class="form-label">Bill Amount :</label>
                                    </div>
                                    <div className='col-4 bg-warning-subtle text-end'>
                                        <label className='font-monospace' class="form-label">{parseFloat(blamt).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0, })}</label>
                                    </div>
                                    <div className='col-8  text-end'>
                                        <label className='font-monospace text-end ' class="form-label">Advance :</label>
                                    </div>
                                    <div className='col-4  text-end'>
                                        <label className='font-monospace' class="form-label">{parseFloat(advanceamount).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0, })}</label>
                                    </div>
                                    <div className='col-8 bg-warning-subtle text-end'>
                                        <strong> <label className='font-monospace text-end' class="form-label">Net Payable Amount :</label></strong>
                                    </div>
                                    <div className='col-4 bg-warning-subtle text-end'>
                                        <strong> <label className='font-monospace bg-danger ' class="form-label">{parseFloat(netpayamt).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0, })}</label></strong>
                                    </div>

                                    <div class="col-md-12  text-end">
                                        <br />
                                        <button id='cmdsave' class="btn btn-primary">Checkout</button>  &nbsp;
                                        <button onClick={handleCloseModalSearchRes} type="submit" class="btn btn-outline-dark">Cancel</button>
                                        <br />
                                        <br />
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                    <div className='hide-on-mobile row bg-info p-1 m-0 container shadow rounded align-content-end border border-1' style={{ backgroundImage: 'linear-gradient(to right, #EC90DE, #ff9999, #6AF4D7)', color: 'black', padding: '20px' }}>

                            <div className='row row text-center align-self-center p-1 m-0 border border-1' style={{ backgroundImage: 'linear-gradient(to right, #6AF4D7, #ff9999, #6AF4D7)', color: 'black', padding: '20px' }}>
                                <div className='col-md-2 align-self-center'>Check In</div>
                                <div className='col-md-1 align-self-center '>Room No</div>
                                <div className='col-md-1 align-self-center text-end '>Rent</div>
                                <div className='col-md-1 align-self-center text-end '>Ex. Bed</div>
                                <div className='col-md-1 align-self-center text-end '>Bed Ch.</div>
                                <div className='col-md-1 align-self-center text-end '>Dis %</div>
                                <div className='col-md-1 align-self-center text-end '>@ Day</div>
                                <div className='col-md-1 align-self-center text-end '>St.Days</div>
                                <div className='col-md-1 align-self-center text-end '>GST %</div>
                                <div className='col-md-1 align-self-center text-end '>GST Amt</div>
                                <div className='col-md-1 align-self-center text-end'>Total</div>
                            </div>

                            {RoomDetails.map((resp, index) => {
                                const dateDifference = Math.floor((new Date(checkoutdate) - new Date(resp[3])) / (1000 * 60 * 60 * 24));
                                const displayStday = dateDifference === 0 ? 1 : dateDifference;
                                var currRent = ((resp[9]) - ((resp[9]) * discountperc / 100))
                                if (Number(GST_P_1) > Number(currRent)) {
                                    billgst = "0";
                                } else if (Number(GST_P_2) > Number(currRent)) {
                                    billgst = "5";
                                } else if (Number(GST_P_3) > Number(currRent)) {
                                    billgst = "12";
                                } else if (Number(GST_P_4) > Number(currRent)) {
                                    billgst = "18";
                                } else if (Number(GST_P_4) <= Number(currRent)) {
                                    billgst = "28";
                                }
                                var currRentGST = (((currRent) * billgst / 100))
                                return (
                                    <div key={index} className='row text-center align-self-center p-1 m-0 bg-light border border-1'>
                                        <div className='col-md-2 align-self-center'>{resp[3] ? new Date(resp[3]).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "2-digit", }) : "No data"}</div>
                                        <div className='col-md-1 align-self-center'>{resp[1]}</div>
                                        <div className='col-md-1 align-self-center text-end '>{resp[9]}</div>
                                        <div className='col-md-1 align-self-center text-end '>0</div>
                                        <div className='col-md-1 align-self-center text-end '>0</div>
                                        <div className='col-md-1 align-self-center text-end '>{discountperc}</div>
                                        <div className='col-md-1 align-self-center text-end '>{resp[9]}</div>
                                        <div className='col-md-1 align-self-center text-end '>{displayStday}</div>
                                        <div className='col-md-1 align-self-center text-end '>{billgst}</div>
                                        <div className='col-md-1 align-self-center text-end '>{currRentGST}</div>
                                        <div className='col-md-1 align-self-center text-end '>{(currRentGST+resp[9])*displayStday}</div>
                                    </div>
                                );
                            })}

                        </div>
                </div>
            </div>

            <Modal
                isOpen={isModalOpenSearchRes}
                onRequestClose={handleCloseModalSearchRes}
                contentLabel="Search Reservation"
                style={{ content: { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "80vw",
                        height: "80vh", backgroundColor: "#fff", overflowY: "auto", zIndex: "1050",
                        display: "flex", flexDirection: "column", padding: "20px", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", },
                    overlay: { position: "fixed", top: "0", left: "0", width: "100vw", height: "100vh", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "1000", },
                }} >
                <div>
                    <h3>Select Room for Checkout</h3>
                    <div>{
                        DataSeacrh && DataSeacrh.length > 0 ? (
                            <div className="row ">
                                <div className="col-md-1 col-6">Name </div>
                                <div className="col-md-5 col-6">: <b>{DataSeacrh[0][26]}</b></div>
                                <div className="col-md-1 col-6">Mobile No</div>
                                <div className="col-md-5 col-6">: <b>{DataSeacrh[0][31]}</b></div>
                                <div className="col-md-1 col-6">Address </div>
                                <div className="col-md-5 col-6">: <b>{DataSeacrh[0][15]}</b></div>
                                <div className="col-md-1 col-6">Company</div>
                                <div className="col-md-5 col-6">: <b>{DataSeacrh[0][20]}</b></div>
                            </div>
                        ) : (
                            <div>Loading data...</div>
                        )
                    }
                    </div>

                    <table className="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th scope="col" className="f-size-on-mobile">Select</th>
                                <th scope="col" className="f-size-on-mobile">Room Name</th>
                                <th scope="col" className="f-size-on-mobile">Room Type</th>
                                <th scope="col" className="f-size-on-mobile">Check-in Date</th>
                                <th scope="col" className="f-size-on-mobile">Check-out Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Display only 10 items */}
                            {DataSeacrh.slice(0, 10).map((res, x) => (
                                <tr key={x} style={{ verticalAlign: "middle", height: "100%" }}>
                                    <td style={{ wordWrap: "break-word" }}><input type="checkbox" className="form-check-input me-2" checked={selectedItems.includes(res[8])} onChange={() => handleCheckboxChange(res[8])} /></td>
                                    <td style={{ wordWrap: "break-word" }}>{res[1]}</td>
                                    <td style={{ wordWrap: "break-word" }}>{res[2]}</td>
                                    <td style={{ wordWrap: "break-word" }}> {res[3] ? new Date(res[3]).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "2-digit", }) : "No data"} </td>
                                    <td style={{ wordWrap: "break-word" }}> {res[3] && res[42] ? new Date(new Date(res[3]).getTime() + res[42] * 24 * 60 * 60 * 1000).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "2-digit", }) : "No data"} </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>

                    {/* Buttons */}
                    <div className="button-container bg-light p-1 border border-1 shadow rounded-1">
                        <div className="row">
                            <div className="col-md-2 col-12 align-self-center ">
                                &nbsp; <input type="checkbox" className="form-check-input" id="selectAll" checked={selectAll} onChange={handleSelectAll} /> &nbsp;
                                <label className="form-check-label" htmlFor="selectAll"> Select All </label>
                            </div>
                            <div className="col-md-10 col-12 text-end">
                                <button className="btn btn-outline-primary" onClick={(e) => handleCheckout(1)}> <i className="fa-solid fa-angles-right"></i> Check-out</button> &nbsp;
                                <button className="btn btn-secondary" onClick={handleCloseModalSearchRes}> Cancel </button>
                            </div>
                        </div>

                    </div>
                    <hr />
                    
                </div>
            </Modal>
            <Modal isOpen={isModalOpenFoodCh} onRequestClose={handleCloseModalFoodCh} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">

                <label className='font-monospace' class="form-label">Food Charge Days</label>
                <input type="number" onChange={(e) => { onChangevaluebill(e) }} name='foodpackagedays' value={foodpackagedays} class="form-control" /> <br />

                <div className="button-container">
                    <button className="yes-button" onClick={(e) => { setIsModalOpenFoodCh(false); }}>Yes</button>
                    <button className="no-button" onClick={handleNoClickFoodCh}>No</button>
                </div>
            </Modal>
            <ToastContainer autoClose={2000}></ToastContainer>
            {isLoading && <Loading />}
        </div>
    )
}
export default CheckOut_Details