import { UserLoginDetails } from '../Hostlink/UserLoginDetails'
import React, { useEffect, useState, useContext, useRef } from 'react'
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import Loading from '../Component/Loading';

const Rpt_Cleaner_Report = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopdeptcode = appState?.[0]?.deptcode || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;
    
    const queryCondition = usershopidref == 0
    ? `${usershopid}`
    : `${usershopidref}`;
    const [RoomType, setRoomType] = useState([]);
    const [Room, setRoom] = useState([]);
    const [deptdata, setdeptdata] = useState([])
    const [data, setData] = useState([]);
    const today = new Date();
    
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        deptcode:usershopdeptcode,
        status: 0
    });
    const [totalSums, setTotalSums] = useState({});

    const { fromdate, todate, status,deptcode } = frmdata;
    const tableRef = useRef(null);
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };
    const loaddata = async (e) => {
        setIsLoading(true)
        try {
            
                const billResponse = await axios.get(`${hostlink}/${usershopid}/ClearLogMasview/${fromdate}/${todate}`);
                const billData = billResponse.data;
                setData(billResponse.data)            
                setIsLoading(false)
        } catch (error) {
            console.log("Data Not Found...");
        }
    };
    useEffect(()=>{
        axios.get(`${hostlink}/${queryCondition}/deptmas`)
        .then((Response) => { setdeptdata(Response.data) })
    },[usershopid])

    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Housekeeping-Report.xlsx');
    };

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-6 align-self-center'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-person-digging"></i> <span id='pagemode'> Housekeeping Report</span></h5>
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="fromdate" className="form-label">From Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="todate" className="form-label">To Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" />
                    </div>
                    {/* <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="roomtype" className="form-label">Department:</label>
                    </div>
                    <div className="col-2 text-start-0 align-self-center">
                            <select id="deptcode" name='deptcode' value={deptcode} onChange={(e) => { onChangevalue(e) }} class="form-select">
                                
                                {deptdata.map((x) => {
                                    return (
                                        <option key={x.id} value={x.id}> {x.deptname} </option>
                                    )
                                })}
                            </select>
                        </div> */}
                    <div className='col-md-12 align-self-center text-end mt-2'>
                        {/* <button onClick={() => { loaddata(0); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Department-wise </button> */}
                        <button onClick={() => { loaddata(1); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show</button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>
                        {/* <PDFDownloadLink document={<PDFDocument data={data} fromdate={fromdate} todate={todate} />} fileName="Reservation Report.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading' : <button className='btn btn-sm btn-outline-primary' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-regular fa-file-pdf"></i> PDF</button>
                            }
                        </PDFDownloadLink> */}
                    </div>
                </div>
            </div>


            <br />
            {/* maxHeight: "400px", */}
            <div className='container-fluid' style={{  overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center' >
                        <tr >
                            <th className='text-start' scope="col">Cl. Date</th>
                            <th className='text-start' scope="col">Cl Time</th>
                            <th className='text-start' scope="col">Cleaner Name</th>
                            <th className='text-start' scope="col">Room No</th>
                            <th className='text-start' scope="col">Status</th>
                            <th className='text-start' scope="col">Remarks 1</th>
                            <th className='text-start' scope="col">Remarks 2</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {data.map((res, x) => (
                            <tr key={x}>
                                
                                <td className={`text-start ${res.status !== 0 ? "bg-danger text-light" : ""}`}>{res.cldate ? new Date(res.cldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</td>
                                <td className={`text-start ${res.status !== 0 ? "bg-danger text-light" : ""}`}>{res.cltime}</td>
                                <td className={`text-start ${res.status !== 0 ? "bg-danger text-light" : ""}`}>{res.cleanername}</td>
                                <td className={`text-start ${res.status !== 0 ? "bg-danger text-light" : ""}`}>{res.roomname}</td>
                                <td className={`text-start ${res.status !== 0 ? "bg-danger text-light" : ""}`}> {res.status == 0 ? "Ready To Checkin" : "Maintenance"}</td>
                                <td className={`text-start ${res.status !== 0 ? "bg-danger text-light" : ""}`}>{res.remark1}</td>
                                <td className={`text-start ${res.status !== 0 ? "bg-danger text-light" : ""}`}>{res.remark2}</td>
                            </tr>
                        ))}
                        <tr class="table-info">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>              
            </div>
            {isLoading && <Loading />}
        </div>
    )
}
// const PDFDocument = ({ data, fromdate, todate }) => {
//     // Calculate total bill amount
//     // const totalBillAmount = data.reduce((total, res) => total + parseFloat(res.SaleMasDTO.totblamt), 0);
//     return (
//         <Document>
//             <Page orientation="landscape">
//                 <View style={styles.container}>
//                     <Text style={styles.title}>Stock Report</Text>
//                     <Text style={styles.title}>From : {fromdate} To : {todate}</Text>
//                     <View style={styles.table}>
//                         <View style={styles.tableRow}>
//                             <Text style={styles.headerCell}>Sr No</Text>
//                             <Text style={styles.headerCell}>Res. Date</Text>
//                             <Text style={styles.headerCell}>Checkin Date</Text>
//                             <Text style={styles.headerCell}>Room Type</Text>
//                             <Text style={styles.headerCell}>Mobile No</Text>
//                             <Text style={styles.headerCell}>Guest Name</Text>
//                             <Text style={styles.headerCell}>Address</Text>
//                             <Text style={styles.headerCell}>Company Name</Text>
//                             <Text style={styles.headerCell}>Stay Days</Text>
//                             <Text style={styles.headerCell}>Rent</Text>
//                             <Text style={styles.headerCell}>Advance</Text>
//                             <Text style={styles.headerCell}>Remarks</Text>
//                         </View>
//                         {data.map((res, x) => (
//                             <View key={x} style={styles.tableRow}>
//                                 <Text style={styles.cell}>{x + 1}</Text>
//                                 <Text style={styles.cell}>{res.resdate ? new Date(res.resdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</Text>
//                                 <Text style={styles.cell}>{res.chdate ? new Date(res.chdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</Text>
//                                 <Text style={styles.cell}>{res.roomtypename}</Text>
//                                 <Text style={styles.cell}>{res.gmob}</Text>
//                                 <Text style={styles.cell}>{res.gname}</Text>
//                                 <Text style={styles.cell}>{res.add1} &nbsp; {res.add2}</Text>
//                                 <Text style={styles.cell}>{res.cname}</Text>
//                                 <Text style={styles.cell}>{res.stday}</Text>
//                                 <Text style={styles.cell}>{res.rent}</Text>
//                                 <Text style={styles.cell}>{res.advance}</Text>
//                                 <Text style={styles.cell}>{res.remarks}</Text>
//                             </View>
//                         ))}
//                         <View style={styles.tableRow}>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                             <Text style={styles.cell}></Text>
//                         </View>
//                     </View>
//                 </View>
//             </Page>
//         </Document>
//     );
// };

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    title: {
        fontSize: 16,
        fontStyle: 'Times New Roman',
        marginBottom: 20,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    headerCell: {
        fontSize: 8,
        fontWeight: 'bold',
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
    cell: {
        fontSize: 8,
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
});

export default Rpt_Cleaner_Report